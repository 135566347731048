import { useEffect, useState } from "react";
import { isMobileDevice } from "../../const";
import "./styles.scss";
import { IoClose } from "react-icons/io5";

import { Link } from "react-scroll";

const Headers = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [isSticky, setIsSticky] = useState(false);

  const handleScreenWidthChange = () => {
    if (window.innerWidth <= 768 || isMobileDevice()) {
      setIsMobile(true);
      document.body.classList.add("is-mobile-menu");
    } else {
      setIsMobile(false);
      document.body.classList.remove("is-mobile-menu");
    }
  };

  useEffect(() => {
    handleScreenWidthChange();
    window.addEventListener("resize", handleScreenWidthChange);

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener("resize", handleScreenWidthChange);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`${isMobile ? "-mobile" : ""} header extended-menu ${(isSticky) ? '-sticky showed' : ''}`}>
      <div className="header-wrap">
        <div className="header-wrap-inner">
          <div className="left-part">
            <div className="mobile-hamburger -left">
              <div
                className="hamburger btn-round btn-round-light"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <div className="atom">
                  <div className="hamburger-holder">
                    <span className="_shape" />
                    <span className="_shape" />
                  </div>
                </div>
              </div>
            </div>
            <div className="branding">
              <a href={window.location.href} className="branding-title font-titles">
                <div className="logo" style={{ display:isSticky ? 'none' : 'flex' }}>
                  <img
                    src="/images/logo-name.png"
                    srcSet="/images/logo-name.png"
                    alt="PT BOCAH GABUT TECHNOLOGY"
                    className="dark-scheme-logo"
                  />
                </div>
              </a>
            </div>
          </div>
          <div className="right-part right">
            <nav className="nav with-mobile-menu visible">
              <div
                className={`mobile-overlay menu-mobile-overlay ${
                  isOpenDrawer && "visible"
                }`}
              >
                <div
                  className="mobile-overlay-bg"
                  onClick={() => setIsOpenDrawer(false)}
                />
                <div
                  className="close-bar text-left"
                  onClick={() => setIsOpenDrawer(false)}
                >
                  <div className="btn-round btn-round-light clb-close">
                    <div className="icons">
                      <IoClose />
                    </div>
                  </div>
                </div>
                <div className="mobile-overlay-container">
                  <div className="nav-container">
                    <ul className="menu" id="primary-menu">
                      <li className="mega-menu-item nav-item current-menu-item ">
                        <Link
                          activeClass="active"
                          to="section-home"
                          spy={true}
                          smooth={true}
                          offset={0}
                          duration={500}
                          className="menu-link main-menu-link item-title"
                        >
                          <span>home</span>
                        </Link>
                      </li>
                      <li className="mega-menu-item nav-item current-menu-item ">
                        <Link
                          activeClass="active"
                          to="section-expertise"
                          spy={true}
                          smooth={true}
                          offset={40}
                          duration={500}
                          className="menu-link main-menu-link item-title"
                        >
                          <span>expertise</span>
                        </Link>
                      </li>
                      <li className="mega-menu-item nav-item current-menu-item ">
                        <a
                          href=""
                          className="menu-link main-menu-link item-title"
                        >
                          <span>work</span>
                        </a>
                      </li>
                      <li className="mega-menu-item nav-item current-menu-item ">
                        <Link
                          activeClass="active"
                          to="section-experience"
                          spy={true}
                          smooth={true}
                          offset={-60}
                          duration={500}
                          className="menu-link main-menu-link item-title"
                        >
                          <span>experience</span>
                        </Link>
                      </li>
                      <li className="mega-menu-item nav-item current-menu-item ">
                        <a
                          href=""
                          className="menu-link main-menu-link item-title"
                        >
                          <span>contact</span>
                        </a>
                      </li>
                    </ul>
                    <ul className="mobile-menu menu" id="mobile-menu">
                      <li className="mega-menu-item nav-item current-menu-item">
                        <Link
                          activeClass="active"
                          to="section-home"
                          spy={true}
                          smooth={true}
                          offset={0}
                          duration={500}
                          className="menu-link main-menu-link item-title"
                          onClick={() => {setIsOpenDrawer(false)}}
                        >
                          <span>home</span>
                        </Link>
                      </li>
                      <li className="mega-menu-item nav-item current-menu-item ">
                        <Link
                          activeClass="active"
                          to="section-expertise"
                          spy={true}
                          smooth={true}
                          offset={isMobile ? -50 : 50}
                          duration={500}
                          className="menu-link main-menu-link item-title"
                          onClick={() => {setIsOpenDrawer(false)}}
                        >
                          <span>expertise</span>
                        </Link>
                      </li>
                      <li className="mega-menu-item nav-item current-menu-item ">
                        <a
                          href=""
                          className="menu-link main-menu-link item-title"
                        >
                          <span>work</span>
                        </a>
                      </li>
                      <li className="mega-menu-item nav-item current-menu-item ">
                        <Link
                          activeClass="active"
                          to="section-experience"
                          spy={true}
                          smooth={true}
                          offset={isMobile ? -50 : 50}
                          duration={500}
                          className="menu-link main-menu-link item-title"
                          onClick={() => {setIsOpenDrawer(false)}}
                        >
                          <span>experience</span>
                        </Link>
                      </li>
                      <li className="mega-menu-item nav-item current-menu-item ">
                        <a
                          href=""
                          className="menu-link main-menu-link item-title"
                        >
                          <span>contact</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="copyright">
                    © 2023. Made by{" "}
                    <a href="" target="_blank" rel="noopener noreferrer">
                      Bocah Gabut
                    </a>
                    .
                    <br />
                    All right reserved.
                  </div>
                </div>
              </div>
            </nav>
            <div className="close-menu" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Headers;
