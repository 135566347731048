import "./styles.scss";

const HomeSection = () => {
  return (
    <section className="section section-motion section-items-middle" id="section-home">
      <div className="section-background-overlay" />
      <div className="section-container">
        <div className="section-col element-column section-col-100">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element-a5ab7d5 elementor-widget elementor-element">
              <div
                className="elementor-widget-container"
              >
                <h1 className="elementor-heading-title elementor-size-default">
                  BOCAH GABUT
                </h1>
              </div>
              <div className="elementor-widget-container" data-aos="fade-up"
                data-aos-anchor-placement="top-bottom" data-aos-delay="300">
                <h5 className="elementor-subheading-title elementor-size-default">
                  Software Engineer, Front end & App Developer.
                </h5>
              </div>
            </div>
            <div className="elementor-element-82668a7 elementor-absolute">
              <div
                className="elementor-widget-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="mouse__container">
                  <svg
                    width="35px"
                    height="100%"
                    viewBox="0 0 247 390"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{
                      fillRule: "evenodd",
                      clipRule: "evenodd",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeMiterlimit: "1.5",
                    }}
                  >
                    <path
                      id="wheel"
                      d="M123.359,79.775l0,72.843"
                      style={{ fill: "none", stroke: "#fff", strokeWidth: 15 }}
                    />
                    <path
                      id="mouse"
                      d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z"
                      style={{ fill: "none", stroke: "#fff", strokeWidth: 15 }}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSection;
