import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

import { FaMapPin } from "react-icons/fa6";
import { FaExternalLinkAlt } from "react-icons/fa";

const SectionExperience = () => {
  return (
    <section
      className="elementor-element-36f7d8c elementor-element elementor-section elementor-reverse-mobile"
      id="section-experience"
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7c589c4">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-339b1c4 fancy-heading elementor-widget elementor-widget-heading">
              <div className="elementor-widget-container" data-aos="fade-up">
                <h2 className="elementor-heading-title elementor-size-default">
                  Professional
                  <br />
                  Experience
                </h2>
              </div>
            </div>
            <div className="elementor-element elementor-element-a646450 elementor-widget elementor-widget-ohio_accordion">
              <div className="elementor-widget-container">
                <Accordion preExpanded={[1]}>
                  <AccordionItem data-aos="fade-up" style={{ marginBottom:10 }} uuid={1} data-aos-duration="300">
                    <AccordionItemHeading>
                      <AccordionItemButton style={{ borderRadius:6 }}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="position">
                            Web Developer @ Upwork Inc.
                          </span>
                          <span className="time">2021 - Present</span>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="elementor">
                        <div className="elementor-section-wrap">
                          <section className="elementor-section elementor-top-section elementor-element elementor-element-7a4a218" style={{ borderRadius:6 }}>
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b3dcea9">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div className="elementor-element elementor-element-11678a6 elementor-icon-list--layout-inline elementor-align-left elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list">
                                    <div className="elementor-widget-container">
                                      <ul className="elementor-icon-list-items elementor-inline-items">
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span
                                            className="elementor-icon-list-icon"
                                            style={{ marginRight: 10 }}
                                          >
                                            <FaMapPin />
                                          </span>
                                          <span className="elementor-icon-list-text">
                                            Remote
                                          </span>
                                        </li>
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <a
                                            href="https://www.upwork.com/freelancers/~016143b1e8af430fc6?viewMode=1"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <span
                                              className="elementor-icon-list-icon"
                                              style={{ marginRight: 10 }}
                                            >
                                              <FaExternalLinkAlt />
                                            </span>
                                            <span className="elementor-icon-list-text">
                                              upwork.com
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="elementor-element elementor-element-2d5edb0 elementor-widget elementor-widget-text-editor">
                                    <div className="elementor-widget-container">
                                      <p>
                                        Freelance developer on Upwork
                                        specializing in Fullstack developer
                                        (React,Laravel) technologies.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="elementor-element elementor-element-8410b4d elementor-icon-list--layout-inline elementor-align-left elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list">
                                    <div className="elementor-widget-container">
                                      <ul className="elementor-icon-list-items elementor-inline-items">
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span className="elementor-icon-list-text">
                                            Javascript
                                          </span>
                                        </li>
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span className="elementor-icon-list-text">
                                            PHP
                                          </span>
                                        </li>
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span className="elementor-icon-list-text">
                                            HTML
                                          </span>
                                        </li>
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span className="elementor-icon-list-text">
                                            CSS
                                          </span>
                                        </li>
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span className="elementor-icon-list-text">
                                            MYSQL
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-037b8d9">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div className="elementor-element elementor-element-6c6239b elementor-widget elementor-widget-image">
                                    <div className="elementor-widget-container">
                                      <img
                                        decoding="async"
                                        height="225"
                                        width="300"
                                        data-src="/images/upwork-logo-300x225.webp"
                                        data-srcset="/images/upwork-logo-300x225.webp"
                                        data-sizes="(max-width: 300px) 100vw, 300px"
                                        src="/images/upwork-logo-300x225.webp"
                                        alt=""
                                        className="attachment-medium size-medium ls-is-cached lazyloaded"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem data-aos="fade-up" style={{ marginBottom:10 }} uuid={2} data-aos-duration="600">
                    <AccordionItemHeading>
                      <AccordionItemButton style={{ borderRadius:6 }}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="position">
                            Fullstack developer @ PT Cidigi Multi Inovasi.
                          </span>
                          <span className="time">2022 - 2023</span>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="elementor">
                        <div className="elementor-section-wrap">
                          <section className="elementor-section elementor-top-section elementor-element elementor-element-7a4a218" style={{ borderRadius:6 }}>
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b3dcea9">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div className="elementor-element elementor-element-11678a6 elementor-icon-list--layout-inline elementor-align-left elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list">
                                    <div className="elementor-widget-container">
                                      <ul className="elementor-icon-list-items elementor-inline-items">
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span
                                            className="elementor-icon-list-icon"
                                            style={{ marginRight: 10 }}
                                          >
                                            <FaMapPin />
                                          </span>
                                          <span className="elementor-icon-list-text">
                                            Cirebon, Indonesia
                                          </span>
                                        </li>
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <a
                                            href="http://cidigiinovasi.com"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <span
                                              className="elementor-icon-list-icon"
                                              style={{ marginRight: 10 }}
                                            >
                                              <FaExternalLinkAlt />
                                            </span>
                                            <span className="elementor-icon-list-text">
                                              Cidgi Multi Inovasi
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="elementor-element elementor-element-2d5edb0 elementor-widget elementor-widget-text-editor">
                                    <div className="elementor-widget-container">
                                      <p>
                                        Developing front-end, back-end and mobile app solutions (B2C, B2B) in Software Tech on React/Next.js framework and Flutter SDK.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="elementor-element elementor-element-8410b4d elementor-icon-list--layout-inline elementor-align-left elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list">
                                    <div className="elementor-widget-container">
                                      <ul className="elementor-icon-list-items elementor-inline-items">
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span className="elementor-icon-list-text">
                                            Javascript
                                          </span>
                                        </li>
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span className="elementor-icon-list-text">
                                            PHP
                                          </span>
                                        </li>
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span className="elementor-icon-list-text">
                                            HTML
                                          </span>
                                        </li>
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span className="elementor-icon-list-text">
                                            CSS
                                          </span>
                                        </li>
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span className="elementor-icon-list-text">
                                            MYSQL
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-037b8d9">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div className="elementor-element elementor-element-6c6239b elementor-widget elementor-widget-image">
                                    <div className="elementor-widget-container">
                                      <img
                                        decoding="async"
                                        height="225"
                                        width="300"
                                        data-src="/images/cidigi-logo.png"
                                        data-srcset="/images/cidigi-logo.png"
                                        data-sizes="(max-width: 300px) 100vw, 300px"
                                        src="/images/cidigi-logo.png"
                                        alt=""
                                        className="attachment-medium size-medium ls-is-cached lazyloaded"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem data-aos="fade-up" style={{ marginBottom:10 }} uuid={3} data-aos-duration="900">
                    <AccordionItemHeading>
                      <AccordionItemButton style={{ borderRadius:6 }}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="position">
                            Internship @ Qintani Digital Printing & Advertising
                          </span>
                          <span className="time">May 2019 - Aug 2019</span>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="elementor">
                        <div className="elementor-section-wrap">
                          <section className="elementor-section elementor-top-section elementor-element elementor-element-7a4a218" style={{ borderRadius:6 }}>
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b3dcea9">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div className="elementor-element elementor-element-11678a6 elementor-icon-list--layout-inline elementor-align-left elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list">
                                    <div className="elementor-widget-container">
                                      <ul className="elementor-icon-list-items elementor-inline-items">
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span
                                            className="elementor-icon-list-icon"
                                            style={{ marginRight: 10 }}
                                          >
                                            <FaMapPin />
                                          </span>
                                          <span className="elementor-icon-list-text">
                                            Cirebon, Indonesia
                                          </span>
                                        </li>
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <a
                                            href="https://qintaniadv.wordpress.com"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <span
                                              className="elementor-icon-list-icon"
                                              style={{ marginRight: 10 }}
                                            >
                                              <FaExternalLinkAlt />
                                            </span>
                                            <span className="elementor-icon-list-text">
                                              Qintani Digital Printing & Advertising
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="elementor-element elementor-element-2d5edb0 elementor-widget elementor-widget-text-editor">
                                    <div className="elementor-widget-container">
                                      <p>
                                        Internship Qintani Digital Printing & Advertising
                                        specializing in Design (Corelldraw,Canva).
                                      </p>
                                    </div>
                                  </div>
                                  <div className="elementor-element elementor-element-8410b4d elementor-icon-list--layout-inline elementor-align-left elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list">
                                    <div className="elementor-widget-container">
                                      <ul className="elementor-icon-list-items elementor-inline-items">
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span className="elementor-icon-list-text">
                                            Corel Draw
                                          </span>
                                        </li>
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span className="elementor-icon-list-text">
                                            Canva
                                          </span>
                                        </li>
                                        <li className="elementor-icon-list-item elementor-inline-item">
                                          <span className="elementor-icon-list-text">
                                            Adobe Light Room
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-037b8d9">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div className="elementor-element elementor-element-6c6239b elementor-widget elementor-widget-image">
                                    <div className="elementor-widget-container">
                                      <img
                                        decoding="async"
                                        height="225"
                                        width="300"
                                        data-src="/images/qintani-logo.png"
                                        data-srcset="/images/qintani-logo.png"
                                        data-sizes="(max-width: 300px) 100vw, 300px"
                                        src="/images/qintani-logo.png"
                                        alt=""
                                        className="attachment-medium size-medium ls-is-cached lazyloaded"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionExperience;
