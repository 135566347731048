import "core-js/stable";
import "regenerator-runtime/runtime";
import 'core-js/actual';
import 'normalize.css';
import 'react-tooltip/dist/react-tooltip.css'

import './assets/styles.scss'

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import AnimatedCursor from "react-animated-cursor"

import './assets/responsive.scss'
import './assets/animations.scss'

ReactDOM.createRoot(document.getElementById('page')!).render(
  <React.StrictMode>
    <AnimatedCursor
      innerSize={8}
      outerSize={40}
      innerScale={0.5}
      outerScale={1.3}
      outerAlpha={0}
      innerStyle={{
        backgroundColor: 'var(--cursor-color)'
      }}
      outerStyle={{
        border: '3px solid var(--cursor-color)',
        mixBlendMode: 'exclusion',
        zIndex:999999
      }}
    />
    <App />
  </React.StrictMode>,
)
