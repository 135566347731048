import Headers from "../headers";
import SectionExperience from "../sections/experience";
import SectionExpertise from "../sections/expertise";
import HomeSection from "../sections/home";
import SectionWorks from "../sections/works";
import "./styles.scss";

const Layouts = () => {
  return (
    <>
      <Headers />
      <div className="site-content">
        <HomeSection />
        <SectionExpertise />
        <SectionWorks />
        <SectionExperience />
      </div>
    </>
  );
};

export default Layouts;
