const SectionWorks = () => {
    return(
        <section className="elementor-section elementor-top-section elementor-element elementor-element-5b38874">
            <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-54d61e4">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-e460ffd elementor-widget">
                            <div className="elementor-widget-container">
                                <div className="grid_1">
                                    <div className="portfolio-sorting text-left">
                                        <ul className="unstyled">
                                            <li>Filter by</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionWorks